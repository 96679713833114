const debugging = window.location.href.includes("localhost");
const debugServer = false;
window.baseUrl = (window.location.href.includes("localhost") && debugServer) ? "http://localhost:3000" : "https://api.emmint.com";

let getCall = async (path = "", params = {}) => {
    let queryParams = "?";
    Object.keys(params).forEach(p => {
        queryParams += p + "=" + params[p] + "&"
    });
    const fullPath = window.baseUrl + path + queryParams;
    return fetch(fullPath, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(response => response.json()).then((res)=>{
        if(debugging) console.log('Debugging', fullPath, res);
        return res;
    }).catch((error) => {
        console.error(fullPath, error);
    })
}

let deleteCall = async (path = "", params = {}) => {
    let queryParams = "?";
    Object.keys(params).forEach(p => {
        queryParams += p + "=" + params[p] + "&"
    });
    return fetch(window.baseUrl + path + queryParams, {
        method: 'DELETE',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(response => response.json()).catch((error) => {
        console.error(error);
    })
}

let postCall = async (path, params) => {
    return fetch(window.baseUrl + path, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify(params),
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(response => response.json()).catch((error) => {
        console.error(error);
    })
}

let CurrentUserId;
let CurrentProfile;
let CurrentProfileData;
let userNameCache = {}; //save this on localstorage
let working_on = {}; //Promises
let getProfileFromCache = async (id, refresh=false) => {
    if(!id) console.trace();
    if (userNameCache[id] && !refresh)
        return userNameCache[id];
    if (working_on[id] && !refresh)
        return working_on[id];
        console.log(id, "not in cache, getting...")
    working_on[id] = getCall("/user/" + id)
    return working_on[id];
}

const API = {
    isLoggedIn: async () => {
        return getCall().then((data) => {
            console.log("isLoggedIn", data)
            if (data && data.status && data.status === 'ok') {
                CurrentUserId = data.userInfo._id;
                CurrentProfile = data.profileInfo._id;
                return true;
            }
            CurrentUserId = '';
            CurrentProfile = '';
            CurrentProfileData = undefined;
            console.log('isLoggedIn returned false');
            return false;
        })
    },
    logIn: async (username, password) => {
        return postCall("/login", {
            username,
            password
        }).then((data) => {
            if (data && data.status === "ok") {
                CurrentUserId = data.user_sid;
                CurrentProfile = data.profile_id;
            }
            return data;
        })
    },
    async logout(){
        console.log("Logging out...")
        return getCall("/logout").then(()=>{
            CurrentUserId = '';
            CurrentProfile = '';
            CurrentProfileData = undefined;
        });
    },
    async signup(username, password, email, profile){
        return postCall("/signup", {
            username,
            password,
            email,
            profile
        }).then((data) => {
            console.log(data)
            if (data && data.status === "ok") {
                CurrentUserId = data.user_sid;
                CurrentProfile = data.profile_id;
            }
            return data;
        });
    },
    async subscribe(subscription){
        return postCall("/subscribe", subscription);
    },
    resetPassword(email){
        return postCall("/resetPassword", {username: email});
    },
    changePassword(newPassword){
        return postCall("/resetPassword", {password: newPassword});
    },
    currentUserId() {
        if (!CurrentUserId) this.isLoggedIn(); //replace with cookie
        return CurrentUserId;
    },
    addPaymentCard(cardInfo){
        return postCall("/payment/card", {cardInfo});
    },
    //Posts
    getPosts(userid) {
        if (userid) return getCall("/post/usr/" + userid);
        return getCall("/post/");
    },
    getPost(postid) {
        return getCall("/post/" + postid);
    },
    getVideo(videoId) {
        return getCall("/post/video/" + videoId);
    },
    deletePost(postid){
        return deleteCall("/post/" + postid);
    },
    updatePost(post){
        return postCall("/post/" + post._id, {content: post.content});
    },
    newPost(content, toProfile, isNews) {
        //Content is expected to be a string.
        let params = { content };
        if(isNews) params.nonOrganicType = "News"
        if (toProfile && CurrentUserId !== toProfile)
            params.toProfile = toProfile;
        return postCall("/post/", params);
    },
    newPostReaction(postid) {
        return postCall("/post/react", { postid });
    },
    removePostReaction(postid) {
        return postCall("/post/unreact", { postid });
    },
    newPostBookmark(postid) {
        return postCall("/post/bookmark", { postid });
    },
    removePostBookmark(postid) {
        return postCall("/post/unbookmark", { postid });
    },
    newPostComment(postid, content) {
        return postCall("/post/comment/", { postid, content });
    },
    newCommentReaction(postid, commentDate) {
        return postCall("/post/comment/react", { postid, commentDate });
    },
    removeCommentReaction(postid, commentDate) {
        return postCall("/post/comment/unreact", { postid, commentDate });
    },
    //Invitations
    newInvitation(name, email){
        return postCall("/user/invite", { name, email });
    },
    getInvitation(email){
        return getCall("/user/invite", { email });
    },
    //Profiles
    async getMe() {
        if (!CurrentProfileData)
            if(!CurrentProfile) await this.isLoggedIn();
            CurrentProfileData = await this.getUserProfile(CurrentProfile);
        return CurrentProfileData;
    },
    getMyProfiles() {
        return getCall("/user/mine");
    },
    getMyProfile() {
        return getCall("/user/" + CurrentProfile);
    },
    updateMyProfile(profile, data) {
        return postCall("/user/myProfile", {profile, data});
    },
    searchProfiles(query){
        return getCall("/user/search", query ? {query} : {}).then((data)=>{
            if(data.status == "ok"){
                data.profiles.forEach((p)=>{
                    userNameCache[p._id] = p;
                });
                return data
            }
        });
    },
    changeProfile(profileid){
        return postCall("/changeProfile", {profileid}).then((profile) =>{
            CurrentProfile = profileid;
            CurrentProfileData = profile;
            return profile;
        });
    },
    getUserProfile(profileid, refresh=false) {
        return getProfileFromCache(profileid, refresh);
    },
    deleteProfile(profileid){
        return deleteCall("/user/" + profileid);
    },
    currentProfileId() {
        if (!CurrentProfile) this.isLoggedIn(); //replace with cookie
        return CurrentProfile;
    },
    followProfile(profileid){
        return getCall("/user/" + profileid + "/follow");
    },
    unfollowProfile(profileid){
        return getCall("/user/" + profileid + "/unfollow");
    },
    setDataValue(key, value){
        return postCall("/user/setData", {key, value});
    },
    //Groups
    newGroup(title, subtitle, description, isPrivate=false, isCourse=false) {
        return postCall("/user/groups", {
            profile: {
                firstName: title,
                lastName: subtitle,
                description
            },
            isPrivate,
            isCourse
        });
    },
    getRecentGroups() {
        return getCall("/user/groups");
    },
    searchGroups(query){
        return getCall("/user/groups/search", query ? {query} : {}).then((data)=>{
            if(data.status == "ok"){
                data.groups.forEach((p)=>{
                    userNameCache[p._id] = p;
                });
                return data
            }
        });
    },
    getCourses() {
        return getCall("/user/courses");
    },
    searchCourses(query){
        return getCall("/user/groups/search", query ? {query, courses: true} : {courses: true}).then((data)=>{
            if(data.status == "ok"){
                data.groups.forEach((p)=>{
                    userNameCache[p._id] = p;
                });
                return data
            }
        });
    },
    getGroup(groupid) {
        return getCall("/user/groups/" + groupid);
    },
    async subscribeToGroup(groupid){
        delete userNameCache[groupid];
        return await getCall("/user/groups/" + groupid + "/subscribe");
    },
    acceptGroupRequest(profileid, groupid){
        delete userNameCache[groupid];
        return postCall("/user/groups/accept", {profileid, groupid});
    },
    rejectGroupRequest(profileid, groupid){
        delete userNameCache[groupid];
        return postCall("/user/groups/reject", {profileid, groupid});
    },
    unsubscribeToGroup(groupid){
        delete userNameCache[groupid];
        return getCall("/user/groups/" + groupid + "/unsubscribe");
    },
    //Payments
    paymentIntent(userid, price, description){
        return postCall("/payments/intent", {userid, price, description});
    },
    paymentRegister(userid, result){
        return postCall("/payments/register/", {userid, result});
    },
    //Songs
    getRecentSongs(){
        return getCall("/songs");
    },
    newSong(title, content, bpm){
        return postCall("/songs", {title, content, bpm});
    },
    updateSongContent(song){
        return postCall("/songs/" + song._id, {content: song.content});
    }
}

export default API;